import styled from "styled-components";
import { Toolbar } from "@material-ui/core";

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RightButton = styled.div`
  display: flex;
  svg {
    fill: #666;
  }
`;

export const MyToolBar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
`;

export const NavMenu = styled.ul`
  display: flex;
  justify-content: flex-start;
`;

export const NavMenuItem = styled.li`
  margin-left: 20px;
  a {
    color: #666;
    font-family: Roboto;
    text-decoration: none;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const AccountTitle = styled.p`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Roboto;
  color: #666;
  .name {
    font-size: 14px;
  }
  .companyName {
    font-size: 12px;
    width: 100%;
    text-align: left;
    color: #666;
  }
`;
