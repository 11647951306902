import React from "react";
import { useAuth } from "hooks";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as Pages from "pages";

export default function Routes() {
  const { me } = useAuth();
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Pages.Documents} />
        <Route exact path="/documents" component={Pages.Documents} />
        <Route exact path="/login" component={Pages.Login} />
        {me && me.is_admin && !me.company_id && (
          <Route exact path="/companies" component={Pages.Companies} />
        )}
        {me && me.is_admin && (
          <>
            <Route exact path="/users" component={Pages.Users} />
            <Route exact path="/logs" component={Pages.Logs} />
          </>
        )}
      </Switch>
    </Router>
  );
}
