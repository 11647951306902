import React from "react";
import { useUsers, useCompanies, useAuth } from "hooks";
import {
  Drawer,
  TextField,
  FormControl,
  Grid,
  Button,
  CircularProgress,
  Switch,
  FormControlLabel,
  MenuItem,
} from "@material-ui/core";
import * as yup from "yup";
import { Form, Formik, Field } from "formik";
import { FormBox, FormButtons, FormTitle } from "assets/css/global";
import InputMask from "react-input-mask";
import { SnackbarContext } from "contexts/SnackbarContext";
import { MoreFolders } from "../../../components";

export default function UserForm(props) {
  const { me } = useAuth();
  const { handleUserStore } = useUsers();
  const { handleCompanies } = useCompanies();
  const { openSnackbar } = React.useContext(SnackbarContext);
  const [allCompanies, setAllCompanies] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const formInitialValue = {
    id: (props.data && props.data.id) || null,
    company_id: (props.data && props.data.company_id) || null,
    name: (props.data && props.data.name) || "",
    surname: (props.data && props.data.surname) || "",
    document: (props.data && props.data.document) || "",
    email: (props.data && props.data.email) || "",
    cellphone: (props.data && props.data.cellphone) || "",
    password: "",
    enabled: props.data && props.data.enabled,
    dropbox_folder: (props.data && props.data.dropbox_folder) || "/",
    dropbox_folders: (props.data && props.data.dropbox_folders) || [],
    is_admin: (props.data && props.data.is_admin) || "",
  };

  const formSchema = yup.object().shape({
    name: yup.string().required("Campo obrigatório"),
    surname: yup.string().required("Campo obrigatório"),
    document: yup.string().required("Campo obrigatório"),
    email: yup.string().email().required("Campo obrigatório"),
    cellphone: yup.string().required("Campo obrigatório"),
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const store = await handleUserStore(values);
      if (store.status === 200) {
        props.onClose(true);
        setLoading(false);
        openSnackbar("Dados atualizados", "success");
      } else {
        openSnackbar("Ops. Parece que o email escolhido já existe.", "warning");
        setLoading(false);
      }
    } catch (e) {
      openSnackbar(
        "Ops. Tivemos um problema, tente novamente mais tarde.",
        "error"
      );
      setLoading(false);
    }
  };

  React.useState(() => {
    const handleCompaniesData = async () => {
      try {
        const { status, data } = await handleCompanies(false);
        console.log(data);
        if (status === 200) {
          setAllCompanies(data);
        }
      } catch (e) {}
    };
    handleCompaniesData();
  }, []);

  return (
    <Drawer anchor="bottom" open={props.open} onClose={() => props.onClose()}>
      <FormBox>
        <FormTitle>
          {props.data && props.data.id ? "Editar" : "Adicionar"} Usuário
        </FormTitle>
        <Formik
          validationSchema={formSchema}
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {() => (
            <Form>
              <Grid container spacing={3}>
                {me.is_admin && me.company_id === null && (
                  <Grid item xs={4}>
                    <FormControl fullWidth margin="dense">
                      <Field name="company_id">
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            select
                            label="Empresa"
                            variant="outlined"
                            fullWidth
                            size="small"
                            error={Boolean(
                              form.errors.company_id && form.touched.company_id
                            )}
                            helperText={
                              form.errors.company_id &&
                              form.touched.company_id &&
                              String(form.errors.company_id)
                            }
                            onChange={(e) => {
                              form.setFieldValue("company_id", e.target.value);
                            }}
                          >
                            {allCompanies &&
                              allCompanies.map((company) => (
                                <MenuItem value={company.id}>
                                  {company.company_name} ({company.document})
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      </Field>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Field name="name">
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label="Nome"
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={Boolean(form.errors.name && form.touched.name)}
                          helperText={
                            form.errors.name &&
                            form.touched.name &&
                            String(form.errors.name)
                          }
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Field name="surname">
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label="Sobrenome"
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={Boolean(
                            form.errors.surname && form.touched.surname
                          )}
                          helperText={
                            form.errors.surname &&
                            form.touched.surname &&
                            String(form.errors.surname)
                          }
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Field name="document">
                      {({ field, form }) => (
                        <InputMask
                          mask="999.999.999-99"
                          onChange={field.onChange}
                          value={form.values.document}
                        >
                          {() => (
                            <TextField
                              name={field.name}
                              label="CPF"
                              variant="outlined"
                              fullWidth
                              size="small"
                              error={Boolean(
                                form.errors.document && form.touched.document
                              )}
                              helperText={
                                form.errors.document &&
                                form.touched.document &&
                                String(form.errors.document)
                              }
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Field name="email">
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label="Email"
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={Boolean(
                            form.errors.email && form.touched.email
                          )}
                          helperText={
                            form.errors.email &&
                            form.touched.email &&
                            String(form.errors.email)
                          }
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Field name="cellphone">
                      {({ field, form }) => (
                        <InputMask
                          mask="(99) 99999-9999"
                          onChange={field.onChange}
                          value={form.values.cellphone}
                        >
                          {() => (
                            <TextField
                              name={field.name}
                              label="Celular"
                              variant="outlined"
                              fullWidth
                              size="small"
                              error={Boolean(
                                form.errors.cellphone && form.touched.cellphone
                              )}
                              helperText={
                                form.errors.cellphone &&
                                form.touched.cellphone &&
                                String(form.errors.cellphone)
                              }
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Field name="password">
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          type="password"
                          label="Senha"
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={Boolean(
                            form.errors.password && form.touched.password
                          )}
                          helperText={
                            props.data && props.data.id
                              ? `Só preencha para alterar a senha`
                              : ""
                          }
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Field name="enabled">
                      {({ field, form }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              {...field}
                              checked={field.value}
                              color="primary"
                            />
                          }
                          label="Perfil ativo"
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Field name="is_admin">
                      {({ field, form }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              {...field}
                              checked={field.value}
                              color="primary"
                              onChange={(e) => {
                                form.setFieldValue(
                                  "is_admin",
                                  e.target.checked
                                );
                              }}
                            />
                          }
                          label="Perfil Administrativo"
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                {props && props.data && props.data.id && (
                  <Grid item xs={12}>
                    <FormTitle>Outras pastas</FormTitle>
                    <FormControl fullWidth margin="dense">
                      <Field name="dropbox_folders">
                        {({ field, form }) => (
                          <>
                            {field &&
                              field.value.length > 0 &&
                              field.value.map((f, index) => (
                                <MoreFolders
                                  key={Math.random()}
                                  data={f}
                                  onFocusOut={(e) => {
                                    const vals = field.value;
                                    vals[index][e.target.name] = e.target.value;
                                    form.setFieldValue("dropbox_folders", vals);
                                  }}
                                  onRemove={() => {
                                    const vals = field.value;
                                    vals.splice(index, 1);
                                    form.setFieldValue("dropbox_folders", vals);
                                  }}
                                />
                              ))}
                            <MoreFolders
                              add={true}
                              onCreate={(newObj) => {
                                console.log(newObj);
                                const vals = field.value;
                                vals.push(newObj);
                                form.setFieldValue("dropbox_folders", vals);
                              }}
                            />
                          </>
                        )}
                      </Field>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormButtons>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => props.onClose()}
                    >
                      Cancelar
                    </Button>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      {loading ? <CircularProgress size={20} /> : "Gravar"}
                    </Button>
                  </FormButtons>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormBox>
    </Drawer>
  );
}
