import styled from "styled-components";

export const LoginPage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Info = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
`;
export const LoginTitle = styled.h1`
  font-size: 18px;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
`;

export const FormBox = styled.div`
  padding: 20px;
  display: flex;
  border-radius: 6px;
  border: 1px solid #ccc;
`;

export const FormSms = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SmsTitle = styled.p`
  width: 100%;
  font-size: 14px;
  font-family: Roboto;
  color: #666;
  margin-top: 15px;
  text-align: center;
`;
