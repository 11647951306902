import { get, store, remove } from "services/CompanyService";

export default function useCompanies() {
  const handleCompanies = async (pagination, page) => {
    return await get(pagination, page);
  };

  const handleCompanyStore = async (data) => {
    return await store(data);
  };

  const handleCompanyDelete = async (id) => {
    return await remove(id);
  };

  return { handleCompanies, handleCompanyStore, handleCompanyDelete };
}
