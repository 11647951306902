import React from "react";
import { useAuth } from "hooks";
import {
  LoginPage,
  FormBox,
  FormSms,
  SmsTitle,
  LoginBox,
  LoginTitle,
} from "./styles";
import logo from "assets/images/logo_white.png";
import {
  TextField,
  FormControl,
  IconButton,
  InputAdornment,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import * as yup from "yup";
import { Form, Formik, Field } from "formik";
import { AuthContext } from "contexts/AuthContext";
import { SnackbarContext } from "contexts/SnackbarContext";
import { firebase } from "services/FirebaseService";

export default function Login() {
  const captchaRef = React.useRef(null);
  const { setAuthToken, setAuthUser } = React.useContext(AuthContext);
  const { openSnackbar } = React.useContext(SnackbarContext);
  const [user, setUser] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [showSms, setShowSms] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { handleLogin } = useAuth();

  const formInitialValue = {
    email: "",
    password: "",
  };

  const formInitialValueSms = {
    sms: "",
  };

  const formSchema = yup.object().shape({
    email: yup
      .string()
      .required("Campo obrigatório")
      .email("Digite um email válido"),
    password: yup.string().required("Campo obrigatório"),
  });

  const formSchemaSms = yup.object().shape({
    sms: yup.string().required("Campo obrigatório"),
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const { status, data } = await handleLogin(values);
      if (status === 200) {
        setLoading(false);
        setUser(data);
        setShowSms(true);
        handleSMS(data);
      } else {
        setLoading(false);
        openSnackbar("Dados incorretos", "error");
        console.log("passei");
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSubmitSms = async (values) => {
    try {
      setLoading(true);
      window.confirmationResult
        .confirm(values.sms)
        .then(async (result) => {
          openSnackbar("Validado com sucesso! Aguarde...", "success");
          const token = user.auth.token;
          delete user.auth;
          setAuthToken(token);
          setAuthUser(user);
          window.location = "/documents";
        })
        .catch((error) => {
          openSnackbar(
            "Código inválido ou número de tentativas excedidas.",
            "error"
          );
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSMS = async (user) => {
    try {
      if (!window.reCaptchaVerifier) {
        window.reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "sign-in-button",
          {
            size: "invisible",
            callback: (response) => {},
          }
        );
        window.reCaptchaVerifier.render();
        setTimeout(() => captchaRef.current.click(), 3000);
      }

      const cellphone = user.cellphone
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace("-", "");

      return firebase
        .auth()
        .signInWithPhoneNumber(`+55${cellphone}`, window.reCaptchaVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          window.reCaptchaVerifier.reset();
        })
        .catch((error) => {
          window.reCaptchaVerifier.reset();
        });
    } catch (e) {}
  };

  React.useEffect(() => {
    if (localStorage.getItem("token")) {
      window.location.href = "/";
    }
  }, []);

  return (
    <LoginPage>
      <LoginBox>
        <LoginTitle>
          <img src={logo} alt="Cloud - Servitec Brasil" width="295" />
        </LoginTitle>

        <FormBox>
          {!showSms ? (
            <Formik
              validationSchema={formSchema}
              initialValues={formInitialValue}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {() => (
                <Form>
                  <FormControl fullWidth margin="dense">
                    <Field name="email">
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          id="email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={Boolean(
                            form.errors.email && form.touched.email
                          )}
                          helperText={
                            form.errors.email &&
                            form.touched.email &&
                            String(form.errors.email)
                          }
                        />
                      )}
                    </Field>
                  </FormControl>
                  <FormControl fullWidth margin="dense">
                    <Field name="password">
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          type="password"
                          id="password"
                          label="Senha"
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={Boolean(
                            form.errors.password && form.touched.password
                          )}
                          helperText={
                            form.errors.password &&
                            form.touched.password &&
                            String(form.errors.password)
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setVisible(!visible)}
                                  onMouseDown={() => setVisible(!visible)}
                                  edge="end"
                                >
                                  {visible ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    </Field>
                  </FormControl>
                  <FormControl fullWidth margin="dense">
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      {loading ? (
                        <CircularProgress size={20} />
                      ) : (
                        "Acessar meu cloud"
                      )}
                    </Button>
                  </FormControl>
                </Form>
              )}
            </Formik>
          ) : (
            <FormSms>
              <Formik
                validationSchema={formSchemaSms}
                initialValues={formInitialValueSms}
                onSubmit={handleSubmitSms}
                enableReinitialize={true}
              >
                {() => (
                  <Form>
                    <FormControl fullWidth margin="dense">
                      <Field name="sms">
                        {({ field, form }) => (
                          <TextField
                            {...field}
                            type="text"
                            id="sms"
                            label="Código de validação"
                            variant="outlined"
                            fullWidth
                            size="small"
                            error={Boolean(form.errors.sms && form.touched.sms)}
                            helperText={
                              form.errors.sms &&
                              form.touched.sms &&
                              String(form.errors.sms)
                            }
                            inputProps={{
                              maxlength: 6,
                            }}
                            autoFocus={true}
                          />
                        )}
                      </Field>
                    </FormControl>
                    <FormControl fullWidth margin="dense">
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                      >
                        {loading ? <CircularProgress size={20} /> : "Validar"}
                      </Button>
                      <SmsTitle>
                        {`Enviamos um SMS para o celular (**)*****-${
                          user &&
                          user.cellphone &&
                          user.cellphone.substr(user.cellphone.length - 4)
                        }`}
                      </SmsTitle>
                    </FormControl>
                    <div id="sign-in-button" ref={captchaRef}></div>
                  </Form>
                )}
              </Formik>
            </FormSms>
          )}
        </FormBox>
      </LoginBox>
    </LoginPage>
  );
}
