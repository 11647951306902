import React from "react";
import { useFiles } from "hooks";
import { Topbar, FileList } from "components";
import { FilesBox, LoadingBox, BoxActions, FolderFilter } from "./styles";
import { AuthContext } from "contexts/AuthContext";
import { SnackbarContext } from "contexts/SnackbarContext";
import {
  CircularProgress,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

export default function Documents() {
  const { handleFiles, handleDownloadFile } = useFiles();
  const { user } = React.useContext(AuthContext);
  const { openSnackbar } = React.useContext(SnackbarContext);
  const [files, setFiles] = React.useState([]);
  const [folder, setFolder] = React.useState("");
  const [lastFolder, setLastFolder] = React.useState(user.dropbox_folder);
  const [loading, setLoading] = React.useState(false);

  const handleDownload = async (fileDownload) => {
    const { status, data } = await handleDownloadFile(fileDownload);
    if (status === 200) {
      window.open(data);
    }
  };

  const handleFolderNavigation = (path) => {
    setLoading(true);
    setFolder(path);
    const arrPath = path.split("/");
    setLastFolder(arrPath.slice(0, -1).join("/"));
    setLoading(false);
  };

  React.useEffect(() => {
    if (folder === user.dropbox_folder) {
      setFolder(`${user.dropbox_folder}/`);
    }
  }, [user.dropbox_folder]);

  React.useEffect(() => {
    const handleData = async () => {
      try {
        setLoading(true);
        const { status, data } = await handleFiles(folder);
        if (status === 200) {
          if (data.result.entries.length === 0) {
            openSnackbar("O diretorio está vazio.", "warning");
            setFolder(`${user.dropbox_folder}/`);
          } else {
            setFiles(data.result.entries);
          }

          setLoading(false);
        }
      } catch (e) {}
    };

    handleData();
  }, [folder]);

  return (
    <>
      <Topbar />
      <FilesBox>
        {!loading && files.length > 0 ? (
          <>
            <FolderFilter>
              <FormControl variant="outlined" size="small">
                <InputLabel id="folders">Alternar para o diretório:</InputLabel>
                <Select
                  size="small"
                  labelId="folders"
                  value={""}
                  onChange={(e) => {
                    setFolder(`${e.target.value}/`);
                  }}
                >
                  {user &&
                    user.dropbox_folders &&
                    user.dropbox_folders.map((folder) => (
                      <MenuItem value={folder.value} key={Math.random()}>
                        {folder.title}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  Selecione um diretório para inciar sua árvore de arquivos
                </FormHelperText>
              </FormControl>
            </FolderFilter>
            <FileList
              rows={files}
              onClick={(type, path, name) => {
                if (type === "folder") {
                  setLoading(true);
                  handleFolderNavigation(path);
                } else {
                  handleDownload(path);
                }
              }}
            />
          </>
        ) : (
          <LoadingBox>
            <CircularProgress size={50} />
          </LoadingBox>
        )}
      </FilesBox>
      <BoxActions>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => handleFolderNavigation(lastFolder)}
          disabled={lastFolder === user.dropbox_folder}
        >
          <ArrowBack />
        </Button>
        &nbsp;
      </BoxActions>
    </>
  );
}
