import React from "react";
import { useAuth } from "hooks";
import { logout } from "services/api/auth";
import { AppBar, IconButton, MenuItem, Menu } from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import {
  Logo,
  LeftSide,
  RightButton,
  NavMenu,
  NavMenuItem,
  MyToolBar,
  AccountTitle,
} from "./styles";
import logo from "assets/images/logo_white.png";

export default function Topbar() {
  const { me } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  return (
    <AppBar position="static">
      {me && (
        <MyToolBar>
          <LeftSide>
            <Logo>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <img src={logo} alt="Cloud - Servitec Brasil" width={150} />
              </IconButton>
            </Logo>
            <NavMenu>
              {me.is_admin && !me.company_id && (
                <NavMenuItem>
                  <a href="/companies">Empresas</a>
                </NavMenuItem>
              )}
              {me.is_admin === 1 ? (
                <>
                  <NavMenuItem>
                    <a href="/users">Usuários</a>
                  </NavMenuItem>
                  <NavMenuItem>
                    <a href="/documents">Meus Arquivos</a>
                  </NavMenuItem>
                  <NavMenuItem>
                    <a href="/logs">Logs</a>
                  </NavMenuItem>
                </>
              ) : (
                <>
                  <NavMenuItem>
                    <a href="/documents">Meus Arquivos</a>
                  </NavMenuItem>
                </>
              )}
            </NavMenu>
          </LeftSide>
          <RightButton>
            <AccountTitle>
              <p className="name">{`${me.name} ${me.surname}`}</p>
              <p className="companyName">
                {(me && me.company && me.company.fantasy_name) || ""}
              </p>
            </AccountTitle>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={(e) => setAnchorEl(e.currentTarget)}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={open}
              onClose={() => setAnchorEl(false)}
            >
              <MenuItem onClick={() => logout()}>Sair</MenuItem>
            </Menu>
          </RightButton>
        </MyToolBar>
      )}
    </AppBar>
  );
}
