import React from "react";
import { useCompanies } from "hooks";
import { Topbar } from "components";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Typography,
  TableBody,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { Add, Edit, Delete } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import { TableBox, TableActions, PaginationBox } from "assets/css/global";
import CompanyForm from "./components/CompanyForm";
import { SnackbarContext } from "contexts/SnackbarContext";

export default function Companies() {
  const { handleCompanies, handleCompanyDelete } = useCompanies();
  const { openSnackbar } = React.useContext(SnackbarContext);
  const [data, setData] = React.useState(null);
  const [paginate, setPaginate] = React.useState(null);
  const [company, setCompany] = React.useState(null);
  const [page, setPage] = React.useState(1);
  const [openModal, setOpenModal] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const handleData = React.useCallback(async () => {
    try {
      const { status, data } = await handleCompanies(true, page);
      if (status === 200) {
        setData(data.data);
        setPaginate({
          lastPage: data.lastPage,
          page: data.page,
          perPage: data.perPage,
          total: data.total,
        });
      }
    } catch (e) {}
  }, [page]);

  const handleDelete = async () => {
    try {
      const { status } = await handleCompanyDelete(company.id);
      if (status === 200) {
        handleData();
        openSnackbar("Registro excluído com sucesso", "success");
      }
    } catch (e) {
      openSnackbar(
        "Ops. Tivemos um problema, tente novamente mais tarde.",
        "error"
      );
    }
  };

  React.useEffect(() => {
    handleData();
  }, [handleData]);

  return (
    <>
      <Topbar />
      <TableBox>
        <TableActions>
          <Typography variant="h6">Empresas</Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<Add />}
            onClick={() => {
              setCompany({});
              setOpenModal(true);
            }}
          >
            Novo Registro
          </Button>
        </TableActions>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Razão Social</TableCell>
              <TableCell>Nome Fantasia</TableCell>
              <TableCell>CNPJ</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((company) => {
                if (!company.is_admin) {
                  return (
                    <TableRow key={Math.random()}>
                      <TableCell>{company.id}</TableCell>
                      <TableCell>{company.company_name}</TableCell>
                      <TableCell>{company.fantasy_name}</TableCell>
                      <TableCell>
                        {company.document || "Não informado"}
                      </TableCell>
                      <TableCell>
                        {company.enabled ? "Ativo" : "Inativo"}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          aria-label="edit"
                          color="primary"
                          onClick={() => {
                            setCompany(company);
                            setOpenModal(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          color="secondary"
                          onClick={() => {
                            setCompany(company);
                            setOpenConfirm(true);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                }
                return false;
              })}
            {data && data.length <= 0 && (
              <TableRow>
                <TableCell colspan={7} style={{ textAlign: "center" }}>
                  Nenhum registro encontrado.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableBox>
      <PaginationBox>
        {paginate && (
          <Pagination
            count={paginate.lastPage}
            color="primary"
            onChange={(e, page) => setPage(page)}
          />
        )}
      </PaginationBox>
      <Dialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Deseja realmente deletar o registro?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            A ação não tem opção de retorno.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => setOpenConfirm(false)}
            color="primary"
          >
            Não
          </Button>
          <Button
            onClick={() => {
              handleDelete(company);
              setOpenConfirm(false);
            }}
            color="primary"
            autoFocus
          >
            Sim, deletar.
          </Button>
        </DialogActions>
      </Dialog>
      <CompanyForm
        open={openModal}
        data={company}
        onClose={(refresh) => {
          if (refresh) {
            handleData();
          }
          setCompany(null);
          setOpenModal(false);
        }}
      />
    </>
  );
}
