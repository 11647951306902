import React from "react";
import { useLogs } from "hooks";
import { Topbar } from "components";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { TableBox, TableActions, PaginationBox } from "assets/css/global";
import moment from "moment";

export default function Logs() {
  const { handleLogs } = useLogs();
  const [data, setData] = React.useState(null);
  const [paginate, setPaginate] = React.useState(null);
  const [page, setPage] = React.useState(1);

  const handleData = React.useCallback(async () => {
    try {
      const { status, data } = await handleLogs(page);
      if (status === 200) {
        setData(data.data);
        setPaginate({
          lastPage: data.lastPage,
          page: data.page,
          perPage: data.perPage,
          total: data.total,
        });
      }
    } catch (e) {}
  }, [page]);

  React.useEffect(() => {
    handleData();
  }, [handleData]);

  return (
    <>
      <Topbar />
      <TableBox>
        <TableActions>
          <Typography variant="h6">Log de atividade</Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              window.open(
                `https://cloudapi.servitecbrasil.com.br/file-log/download?t=${localStorage.getItem(
                  "token"
                )}`
              )
            }
          >
            Baixar Relatório
          </Button>
        </TableActions>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Empresa</TableCell>
              <TableCell>Usuário</TableCell>
              <TableCell>Arquivo</TableCell>
              <TableCell>Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.length > 0 &&
              data.map((log) => (
                <TableRow key={Math.random()}>
                  <TableCell>
                    {log.company ? log.company.fantasy_name : "Não definido"}
                  </TableCell>
                  <TableCell>{`${log.user.name} ${log.user.surname}`}</TableCell>
                  <TableCell>{log.file_name}</TableCell>
                  <TableCell>
                    {moment(log.created_at).format("DD/MM/YYYY HH:mm:ss")}
                  </TableCell>
                </TableRow>
              ))}
            {data && data.length <= 0 && (
              <TableRow>
                <TableCell colspan={7} style={{ textAlign: "center" }}>
                  Nenhum registro encontrado.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableBox>
      <PaginationBox>
        {paginate && (
          <Pagination
            count={paginate.lastPage}
            color="primary"
            onChange={(e, p) => setPage(p)}
          />
        )}
      </PaginationBox>
    </>
  );
}
