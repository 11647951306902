// eslint-disable-next-line import/no-anonymous-default-export
export default {
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#FFF",
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: "#5195f1",
      },
    },
  },
};
