import React from "react";
import { useCompanies } from "hooks";
import {
  Drawer,
  TextField,
  FormControl,
  Grid,
  Button,
  CircularProgress,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import * as yup from "yup";
import { Form, Formik, Field } from "formik";
import { FormBox, FormButtons, FormTitle } from "assets/css/global";
import InputMask from "react-input-mask";
import { SnackbarContext } from "contexts/SnackbarContext";
import { MoreFolders } from "../../../components";

export default function CompanyForm(props) {
  const { handleCompanyStore } = useCompanies();
  const { openSnackbar } = React.useContext(SnackbarContext);
  const [loading, setLoading] = React.useState(false);

  const formInitialValue = {
    id: (props.data && props.data.id) || null,
    company_name: (props.data && props.data.company_name) || "",
    fantasy_name: (props.data && props.data.fantasy_name) || "",
    dropbox_folder: (props.data && props.data.dropbox_folder) || "",
    dropbox_folders: (props.data && props.data.dropbox_folders) || [],
    document: (props.data && props.data.document) || "",
    enabled: props.data && props.data.enabled,
  };

  const formSchema = yup.object().shape({
    company_name: yup.string().required("Campo obrigatório"),
    fantasy_name: yup.string().required("Campo obrigatório"),
    dropbox_folder: yup.string().required("Campo obrigatório"),
    document: yup.string().required("Campo obrigatório"),
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const store = await handleCompanyStore(values);
      if (store.status === 200) {
        props.onClose(true);
        setLoading(false);
        openSnackbar("Dados atualizados", "success");
      }
    } catch (e) {
      openSnackbar(
        "Ops. Tivemos um problema, tente novamente mais tarde.",
        "error"
      );
      setLoading(false);
    }
  };

  return (
    <Drawer anchor="bottom" open={props.open} onClose={() => props.onClose()}>
      <FormBox>
        <FormTitle>
          {props.data && props.data.id ? "Editar" : "Adicionar"} Empresa
        </FormTitle>
        <Formik
          validationSchema={formSchema}
          initialValues={formInitialValue}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {() => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Field name="company_name">
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label="Razão Social"
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={Boolean(
                            form.errors.company_name &&
                              form.touched.company_name
                          )}
                          helperText={
                            form.errors.company_name &&
                            form.touched.company_name &&
                            String(form.errors.company_name)
                          }
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Field name="fantasy_name">
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label="Nome Fantasia"
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={Boolean(
                            form.errors.fantasy_name &&
                              form.touched.fantasy_name
                          )}
                          helperText={
                            form.errors.fantasy_name &&
                            form.touched.fantasy_name &&
                            String(form.errors.fantasy_name)
                          }
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Field name="document">
                      {({ field, form }) => (
                        <InputMask
                          mask="99.999.999/9999-99"
                          onChange={field.onChange}
                          value={form.values.document}
                        >
                          {() => (
                            <TextField
                              name={field.name}
                              label="CNPJ"
                              variant="outlined"
                              fullWidth
                              size="small"
                              error={Boolean(
                                form.errors.document && form.touched.document
                              )}
                              helperText={
                                form.errors.document &&
                                form.touched.document &&
                                String(form.errors.document)
                              }
                            />
                          )}
                        </InputMask>
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Field name="dropbox_folder">
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          label="Pasta do Dropbox"
                          variant="outlined"
                          fullWidth
                          size="small"
                          error={Boolean(
                            form.errors.dropbox_folder &&
                              form.touched.dropbox_folder
                          )}
                          helperText={
                            form.errors.dropbox_folder &&
                            form.touched.dropbox_folder &&
                            String(form.errors.dropbox_folder)
                          }
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="dense">
                    <Field name="enabled">
                      {({ field, form }) => (
                        <FormControlLabel
                          control={
                            <Switch
                              {...field}
                              checked={field.value}
                              color="primary"
                            />
                          }
                          label="Empresa ativa"
                        />
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormTitle>Outras pastas</FormTitle>
                  <FormControl fullWidth margin="dense">
                    <Field name="dropbox_folders">
                      {({ field, form }) => (
                        <>
                          {field &&
                            field.value.length &&
                            field.value.map((f, index) => (
                              <MoreFolders
                                key={Math.random()}
                                data={f}
                                onFocusOut={(e) => {
                                  const vals = field.value;
                                  vals[index][e.target.name] = e.target.value;
                                  form.setFieldValue("dropbox_folders", vals);
                                }}
                                onRemove={() => {
                                  const vals = field.value;
                                  vals.splice(index, 1);
                                  form.setFieldValue("dropbox_folders", vals);
                                }}
                              />
                            ))}
                          <MoreFolders
                            add={true}
                            onCreate={(newObj) => {
                              console.log(newObj);
                              const vals = field.value;
                              vals.push(newObj);
                              form.setFieldValue("dropbox_folders", vals);
                            }}
                          />
                        </>
                      )}
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormButtons>
                    <Button
                      type="button"
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => props.onClose()}
                    >
                      Cancelar
                    </Button>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      {loading ? <CircularProgress size={20} /> : "Gravar"}
                    </Button>
                  </FormButtons>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormBox>
    </Drawer>
  );
}
